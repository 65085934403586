import { createTheme } from "@mui/material";

export const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#F1C40F",
      contrastText: "#fff", //button text white instead of black
    },
    background: {
      default: "#FEFCE6",
    },
  },
});
