const CardGroup = ({ children }) => {
  // const { colors } = useThemeContext();
  // style={{ backgroundColor: "#FFFDEC" }}
  return (
    <>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          //   alignItems: "center",
          flexDirection: "column",
          // minWidth: 200,
          // maxWidth: 500,
          maxWidth: 550,
          padding: 30,
        }}
      >
        {children}
      </main>
    </>
  );
};

export default CardGroup;
