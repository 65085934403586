import {
  Button,
  Card,
  CardContent,
  Typography,
  ThemeProvider,
  Box,
} from "@mui/material";
import React from "react";
import { buttonTheme } from "../styles/buttonTheme.js";

import whitePaper from "../assets/lemon_whitepaper_v11.pdf";
// import smartContractIcon from "../assets/petition_64.png";
import twitterIcon from "../assets/twitter_circled_48.png";
import telegramIcon from "../assets/telegram_app_48.png";
import { TextTheme } from "../contexts/TextTheme.js";

const contractLink =
  "https://bscscan.com/address/0xdCd6C4fae40d8e0aBdfE3f2adB6D106f01637325";
const twitterLink = "https://twitter.com/bnb_lemon";
const telegramLink = "https://t.me/bnblemon/";
const metaMaskLink = "https://metamask.io/download//";

export default function TopCard(props) {
  return (
    <ThemeProvider theme={TextTheme}>
      <Card
        sx={{
          // minWidth: props.minWidth ?? 275,
          // width: props.width ?? "90%",
          boxShadow: 7,
          borderRadius: "30px",
        }}
        style={{ backgroundColor: "#FEF8D7" }}
      >
        <CardContent sx={{ margin: 1 }}>
          <Typography variant="h4" sx={{ marginBottom: 2.5 }}>
            <Box component="span" fontWeight="bold">
              {props.projectName}
            </Box>
          </Typography>

          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="title">{props.projectName}</h1>
          </div> */}

          <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
            High Reward. Low Maintenance.
          </Typography>

          <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
            Fully Transparent. With BNB LEMON
          </Typography>

          <ThemeProvider theme={buttonTheme}>
            {/* {!address && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 25,
                    paddingBottom: 25,
                  }}
                >
                  <Button variant="contained" onClick={connectWalletHandler}>
                    {connectButtonText}
                  </Button>
                  <div style={{ padding: 3 }} />
                  <Button
                    variant="contained"
                    href={metaMaskLink}
                    target="_blank"
                  >
                    Download MetaMask
                  </Button>
                </div>
              )} */}
            {/* <ButtonGroup
                    variant="text"
                    aria-label="outlined primary button group"
                    size="large"
                    // labelStyle={{ fontSize: "63px" }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      // padding: 20,
                    }}
                  >
                    <Link target={"_blank"} to={metaMaskLink}>
                  Your Link
                </Link>
                  </ButtonGroup> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 25,
                paddingBottom: 25,
              }}
            >
              {props.isMobile && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    //   alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      href={contractLink}
                      target="_blank"
                      style={{ margin: 5 }}
                    >
                      Contract
                    </Button>
                    <Button
                      variant="contained"
                      href={whitePaper}
                      target="_blank"
                      style={{ margin: 5 }}
                    >
                      whitepaper
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Button href={telegramLink} target="_blank">
                      <img
                        src={telegramIcon}
                        alt="img"
                        // onClick={this.myfunction}
                      />
                    </Button>
                    <Button href={twitterLink} target="_blank">
                      <img
                        src={twitterIcon}
                        alt="img"
                        // onClick={this.myfunction}
                      />
                    </Button>
                  </div>
                </div>
              )}

              {!props.isMobile && (
                <div>
                  <Button
                    variant="contained"
                    href={contractLink}
                    target="_blank"
                    style={{ margin: 5 }}
                  >
                    Contract
                  </Button>
                  <Button
                    variant="contained"
                    href={whitePaper}
                    target="_blank"
                    style={{ margin: 5 }}
                  >
                    whitepaper
                  </Button>

                  <Button href={telegramLink} target="_blank">
                    <img
                      src={telegramIcon}
                      alt="img"
                      // onClick={this.myfunction}
                    />
                  </Button>
                  <Button href={twitterLink} target="_blank">
                    <img
                      src={twitterIcon}
                      alt="img"
                      // onClick={this.myfunction}
                    />
                  </Button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {/* <ButtonGroup
                variant="text"
                aria-label="outlined primary button group"
                size="large"
                // labelStyle={{ fontSize: "63px" }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  // padding: 20,
                }}
              >
              <Button startIcon="../assets/icons8-petition-64.png">
                {" "}
                test
              </Button>
              </ButtonGroup> */}

          {/* <ButtonGroup
              // variant="text"
              aria-label="outlined primary button group"
              size="large"
              // labelStyle={{ fontSize: "63px" }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // padding: 20,
              }}
            ></ButtonGroup> */}

          {/* <div style={{ display: "flex", justifyContent: "center" }}>



              <Button href={telegramLink} target="_blank">
                <img
                  src={telegramIcon}
                  alt="img"
                  // onClick={this.myfunction}
                />
              </Button>
              <Button href={twitterLink} target="_blank">
                <img
                  src={twitterIcon}
                  alt="img"
                  // onClick={this.myfunction}
                />
              </Button>
            </div> */}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
