import React from "react";
import { css, Global } from "@emotion/react";
import Image from "../assets/bnb_lemon.png";

// import Header from "./Header";
// import Footer from "./Footer";
// import { useThemeContext } from "../contexts/ThemeContext";

// const globalStyle = (colors) => css`
//   body {
//     color: ${colors.gray[9]};
//     background: ${colors.gray[1]};
//     transition-duration: 0.3s;
//     transition-timing-function: ease;
//     transition-property: border, background, color;
//   }

//   main {
//     flex: 1;
//     margin: 0 auto;
//     max-width: 1280px;
//     display: flex;
//     flex-direction: column;
//   }
// `;

// const background = () => css`
//   scrollingImageContainer {
//     overflow: hidden;
//     background-color: "#F7EE6A";
//   }

//   scrollingImage {
//     background: url("./bnb_lemon.png") repeat-x;
//     height: 750px;
//     width: 7680px;
//   }
// `;

const AppLayout = ({ children }) => {
  const color1 = "#FAE361";
  const color2 = "#EFD82D";
  const color3 = "#F9E971";
  const color4 = "#FFEC8D";

  const background = {
    backgroundColor: color4,
    width: "100%",
    // backgroundImage: "linear-gradient(to right, #FED537, #FFEC8D)",
    backgroundImage: "linear-gradient(to right, #FED537, #FFEFB0)",
    // backgroundImage: "linear-gradient(to right, #4880EC, #019CAD)",
    // background: `linear-gradient(${color1}, ${color2})`,
  };

  // const infiniteImage = {
  //   // background: url("./bnb_lemon.png") repeat-x;
  //   // backgroundImage: "url('asset/bnb_lemon.png')",
  //   backgroundRepeat: "repeat",
  //   height: "750px",
  //   width: "7680px",
  // };

  return (
    <>
      {/* <div class={background.scrollingImageContainer} style={background}> */}
      <div style={background}>
        <div
        // style={{
        //   backgroundImage: `url(${Image})`,
        //   backgroundRepeat: "repeat",
        // }}
        >
          {/* <Global styles={[globalStyle(colors)]} /> */}
          <Global />
          {/* need to set up <Router> in order to use <Header /> */}
          <main>{children}</main>

          {/* need to set up <Router> in order to use <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default AppLayout;
