import logo from "./logo.svg";
import "./App.css";
import Button from "@mui/material/Button";
import HomePage from "./pages/HomePage";
import AppLayout from "./layouts/AppLayout";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "BNB LEMON";
  }, []);

  return (
    <AppLayout>
      <HomePage />
    </AppLayout>
  );
}

export default App;
