import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField, ThemeProvider } from "@mui/material";

import { TextTheme } from "../contexts/TextTheme";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function defaultReferralLink(siteLink, address) {
  if (siteLink === null) {
    return null;
  }

  if (address === null) {
    return "Please connect your wallet to get the link";
  }

  return siteLink + "/" + address;
}

export default function ReferalCard(props) {
  // const [address, setAddress] = React.useState(null);
  // React.useEffect(() => {
  //   console.log("ReferalCard address", props.address);
  //   console.log(
  //     "defaultReferralLink",
  //     defaultReferralLink(props.siteLink, props.address)
  //   );
  // }, []);

  // console.log("ReferalCard address outside", props.address);
  // console.log(
  //   "defaultReferralLink outside",
  //   defaultReferralLink(props.siteLink, props.address)
  // );

  return (
    <ThemeProvider theme={TextTheme}>
      <Card
        sx={{ minWidth: 275, boxShadow: 7, borderRadius: "30px" }}
        style={{ backgroundColor: "#FEF8D7" }}
      >
        <CardContent sx={{ margin: 1 }}>
          <Typography variant="h4" sx={{ marginBottom: 2.5 }}>
            <Box component="span" fontWeight="bold">
              Referral Link:
            </Box>
          </Typography>

          {/* <h1 className="title">Referral Link:</h1> */}
          {/* <Typography variant="h4">REFERRAL LINK</Typography> */}

          <div style={{ marginBottom: 25 }} />

          <TextField
            id="outlined-basic"
            label="Referral Link"
            variant="outlined"
            defaultValue={defaultReferralLink(props.siteLink, props.address)}
            key={props.address}
            // disabled={
            //   defaultReferralLink(props.siteLink, props.address) ? false : true
            // }
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />

          <div style={{ marginBottom: 25 }} />

          <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
            Earn 12% of the BNB used to (buy lemons) from anyone who uses your
            referral link
          </Typography>

          {/* <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
        </CardContent>
        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </Card>
    </ThemeProvider>
  );
}
