import { useState, useEffect } from "react";
import "./CountDownTimer.css";
import { getRemainingTimeUntilMsTimestamp } from "../Timer/Utils/CountDownTimerUtils";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { TextTheme } from "../../contexts/TextTheme";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }

  return (
    <div className="countdown-timer">
      <ThemeProvider theme={TextTheme}>
        {/* <Typography
          sx={{ fontSize: 24, fontWeight: "bold" }}
          color="text.primary"
        >
          Time till launching:
        </Typography> */}
        <Typography
          sx={{ fontSize: 24, fontWeight: "regular" }}
          color="text.primary"
        >
          {remainingTime.days} days {remainingTime.hours} hours{" "}
          {remainingTime.minutes} minutes {remainingTime.seconds} seconds{" "}
          {/* <Box component="span" fontWeight="regular">
            till launching
          </Box> */}
        </Typography>
        <Typography
          sx={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}
          color="text.primary"
        >
          till launching
        </Typography>
      </ThemeProvider>
    </div>
  );
};

export default CountdownTimer;
