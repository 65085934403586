import { createTheme } from "@mui/material";

export const TextTheme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          src: url('../fonts/montserrat-v23-latin-regular.eot'); /* IE9 Compat Modes */
          src: local(''),
                url('../fonts/montserrat.woff2') format('woff2')
                url('../fonts/montserrat-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
                url('../fonts/montserrat-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
                url('../fonts/montserrat-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
                url('../fonts/montserrat-v23-latin-regular.svg') format('svg'); /* Legacy iOS */
        }
      `,
    },
  },
});
