import { ethers } from "ethers";
import Web3 from "web3";

let selectedAccount;
let contract;
let isInitialized = false;

export const init = async () => {
  let provider = window.ethereum;

  console.log("Web3.givenProvider:", Web3.givenProvider);

  if (typeof provider !== "undefined") {
    provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        selectedAccount = accounts[0];
        // console.log(`(Web3Client) Selected account is ${selectedAccount}`);
      })
      .catch((err) => {
        // console.log(err);
        return;
      });

    window.ethereum.on("accountsChanged", function (accounts) {
      selectedAccount = accounts[0];
      // console.log(
      //   `(Web3Client) Selected account changed to ${selectedAccount}`
      // );
    });
  } else {
    // console.log("metamask is not installed!!!");
  }

  const web3 = new Web3(provider);

  // const networkId = await web3.eth.net.getId();

  // const contractAbi = [
  //   { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  //   {
  //     anonymous: false,
  //     inputs: [
  //       {
  //         indexed: true,
  //         internalType: "address",
  //         name: "previousOwner",
  //         type: "address",
  //       },
  //       {
  //         indexed: true,
  //         internalType: "address",
  //         name: "newOwner",
  //         type: "address",
  //       },
  //     ],
  //     name: "OwnershipTransferred",
  //     type: "event",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "adr", type: "address" }],
  //     name: "beanRewards",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "ref", type: "address" }],
  //     name: "buyEggs",
  //     outputs: [],
  //     stateMutability: "payable",
  //     type: "function",
  //   },
  //   {
  //     inputs: [
  //       { internalType: "uint256", name: "eth", type: "uint256" },
  //       { internalType: "uint256", name: "contractBalance", type: "uint256" },
  //     ],
  //     name: "calculateEggBuy",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "uint256", name: "eth", type: "uint256" }],
  //     name: "calculateEggBuySimple",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "uint256", name: "eggs", type: "uint256" }],
  //     name: "calculateEggSell",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [],
  //     name: "getBalance",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "adr", type: "address" }],
  //     name: "getEggsSinceLastHatch",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "adr", type: "address" }],
  //     name: "getMyEggs",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "adr", type: "address" }],
  //     name: "getMyMiners",
  //     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "ref", type: "address" }],
  //     name: "hatchEggs",
  //     outputs: [],
  //     stateMutability: "nonpayable",
  //     type: "function",
  //   },
  //   {
  //     inputs: [],
  //     name: "owner",
  //     outputs: [{ internalType: "address", name: "", type: "address" }],
  //     stateMutability: "view",
  //     type: "function",
  //   },
  //   {
  //     inputs: [],
  //     name: "renounceOwnership",
  //     outputs: [],
  //     stateMutability: "nonpayable",
  //     type: "function",
  //   },
  //   {
  //     inputs: [],
  //     name: "seedMarket",
  //     outputs: [],
  //     stateMutability: "payable",
  //     type: "function",
  //   },
  //   {
  //     inputs: [],
  //     name: "sellEggs",
  //     outputs: [],
  //     stateMutability: "nonpayable",
  //     type: "function",
  //   },
  //   {
  //     inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
  //     name: "transferOwnership",
  //     outputs: [],
  //     stateMutability: "nonpayable",
  //     type: "function",
  //   },
  // ];

  const contractAbi = [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      inputs: [{ internalType: "address", name: "adr", type: "address" }],
      name: "beanRewards",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "ref", type: "address" }],
      name: "buyEggs",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "eth", type: "uint256" },
        { internalType: "uint256", name: "contractBalance", type: "uint256" },
      ],
      name: "calculateEggBuy",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "eth", type: "uint256" }],
      name: "calculateEggBuySimple",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "eggs", type: "uint256" }],
      name: "calculateEggSell",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getBalance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "adr", type: "address" }],
      name: "getEggsSinceLastHatch",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "adr", type: "address" }],
      name: "getMyEggs",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "adr", type: "address" }],
      name: "getMyMiners",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "ref", type: "address" }],
      name: "hatchEggs",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "seedMarket",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "sellEggs",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  contract = new web3.eth.Contract(
    contractAbi,
    // "0x5BeD46B5CbEc5Ca287a618A50b760EFA14128f1e" // (deprecated) bsc testnet 1
    // "0x1Ce550aeCA4cbb98F3F39c8A760837B5a3503B66" // bsc testnet prelaunch
    // "0x228407e39924AA13c872514062Aa2C1E9cd2856D" // (TESTING!!) main net
    // "0xaBBE4d949Cf7AE6e38C252560da621747e79e9d7" // (deprecated) main net
    "0xdCd6C4fae40d8e0aBdfE3f2adB6D106f01637325" // (using new wallet) main net
  );

  isInitialized = true;
};

export const getCurrentAddress = async () => {
  let provider = window.ethereum;
  if (typeof provider !== "undefined") {
    const address = provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        selectedAccount = accounts[0];
        // console.log("(Web3Client) getCurrentAddress: ", selectedAccount);
        let reformat = Web3.utils.toChecksumAddress(selectedAccount);
        return reformat;
      })
      .catch((err) => {
        // console.log(err);
        return null;
      });

    return address;
  } else {
    // console.log("metamask is not installed!!!");
  }
};

export const getContractTotalBalance = async () => {
  if (!isInitialized) {
    await init();
  }

  // return (
  //   contract.methods
  //     .getBalance()
  //     // .send({ from: selectedAccount })
  //     .call()
  //     .then((balance) => {
  //       // console.log(balance);
  //       return balance;
  //       // return Web3.utils.fromWei(balance) * 10 ** 18;
  //     })
  // );

  const balance = contract.methods
    .getBalance()
    .call()
    .then((balance) => {
      return balance;
    });

  return balance;
};

// function getAccountBalance(account) {
//   window.ethereum
//     .request({ method: "eth_getBalance", params: [account, "latest"] })
//     .then((balance) => {
//       setContractBalance(ethers.utils.formatEther(balance));
//     })
//     .catch((error) => {
//       setErrorMessage(error.message);
//     });
// }

// buggy
export const getUserWalletBalance = async (address) => {
  if (!isInitialized) {
    await init();
  }

  let provider = window.ethereum;
  const web3 = new Web3(provider);

  // window.ethereum
  //   .request({ method: "eth_getBalance", params: [selectedAccount, "latest"] })
  //   .then((balance) => {
  //     console.log("using old method");
  //     return ethers.utils.formatEther(balance);
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //   });

  return web3.eth
    .getBalance(address)
    .then((balance) => {
      // console.log(balance, "in getUserWalletBalance");
      return balance;
    })
    .catch((error) => {
      console.error(error.message);
    });
};

// eg. CATS, COIN, etc
export const getUserMinerNumbers = async (address) => {
  if (!isInitialized) {
    await init();
  }

  return contract.methods
    .getMyMiners(address)
    .call()
    .then((miners) => {
      // console.log("current miners: ", miners);
      return miners;
    });
};

export const buyEggs = async (ref, value) => {
  if (!isInitialized) {
    await init();
  }

  // alert(ref ? ref : selectedAccount);

  // return await contract.methods
  return contract.methods
    .buyEggs(ref ? ref : selectedAccount)
    .send({ from: selectedAccount, value: Web3.utils.toWei(value) });
};

export const sellEggs = async () => {
  if (!isInitialized) {
    await init();
  }

  return (
    contract.methods
      .sellEggs()
      // .call();
      .send({ from: selectedAccount })
  );
};

export const getReward = async (address) => {
  if (!isInitialized) {
    await init();
  }

  return contract.methods.beanRewards(address).call();

  // .beanRewards() // wrong
  // .send({ from: selectedAccount })
  // .then(console.log)
  // .catch((error) => {
  //   // console.log(`ERROR in getRewards ${error.message}`);
  //   console.log(error);
  // })
  // .then(function (receipt) {
  //   // receipt can also be a new contract instance, when coming from a "contract.deploy({...}).send()"
  //   console.log("receipt: ", receipt);
  // })
  // .on("confirmation", function (confirmationNumber, receipt) {
  //   console.log("confirmationNumber: ", confirmationNumber);
  // })

  // return await contract.methods.beanRewards({ selectedAccount }).call();
  // return contract.methods
  //   .beanRewards({ selectedAccount })
  //   .call()
  //   .then(console.log)
  //   .then((value) => {
  //     return value;
  //   });
};

export const hatchEggs = async () => {
  if (!isInitialized) {
    await init();
  }

  // return await contract.methods
  return (
    contract.methods
      .hatchEggs(selectedAccount)
      // .call()
      .send({ from: selectedAccount })
      .then(console.log)
      .catch((error) => {
        // console.log("error: ", error);
      })
  );
};

// export const interactWithSc = async () => {
//   if (!isInitialized) {
//     await init();
//   }

//   console.log("for testing interaction only");
//   console.log("selectedAccount: ", selectedAccount);
//   //   return contract.methods.seedMarket().send({ from: selectedAccount });

//   //   return contract.methods.getBalance().send({ from: selectedAccount });

//   return contract.methods
//     .buyEggs(selectedAccount)
//     .send({ from: selectedAccount, value: "1" });
// };
