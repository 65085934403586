import React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, ThemeProvider } from "@mui/material";
import { TextField, createTheme } from "@mui/material";
import { buyEggs, hatchEggs, sellEggs } from "../web3/Web3Client";

import "typeface-montserrat";

// import GothamWoff2 from "../fonts/gotham.woff2";

// const theme = createTheme({
//   typography: {
//     fontFamily: "Gotham, sans-serif",
//     // fontFamily: "Gotham Black, sans-serif",
//     // fontFamily: "Gotham Light, sans-serif",
//     // fontFamily: "Gotham Thin, sans-serif",
//     // fontFamily: "Gotham XLight, sans-serif",
//     // fontFamily: "Gotham Book, sans-serif",
//     // fontFamily: "Gotham Ultra, sans-serif",
//   },
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: `
//         @font-face {
//           fontFamily: 'Gotham';
//           font-style: normal;
//           font-display: swap;
//           font-weight: 500;
//           src: local('Gotham'), local('Gotham Black'), url(${GothamWoff2}) format('woff2');
//           unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
//         }
//       `,
//     },
//   },
// });

// const theme = createTheme({
//   typography: {
//     fontFamily: "Montserrat, sans-serif",
//   },
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: `
//         @font-face {
//           fontFamily: 'Montserrat';
//           font-style: normal;
//           font-display: swap;
//           font-weight: 500;
//           src: local('montserrat'), url(${MontserratWoff2}) format('woff2');
//           unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
//         }
//       `,
//     },
//   },
// });

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          src: url('../fonts/montserrat-v23-latin-regular.eot'); /* IE9 Compat Modes */
          src: local(''),
                url('../fonts/montserrat.woff2') format('woff2')
                url('../fonts/montserrat-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
                url('../fonts/montserrat-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
                url('../fonts/montserrat-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
                url('../fonts/montserrat-v23-latin-regular.svg') format('svg'); /* Legacy iOS */
        }
      `,
    },
  },
});

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#F1C40F",
      contrastText: "#fff", //button text white instead of black
    },
    background: {
      default: "#FEFCE6",
    },
  },
});

const buttonHeight = "48px";

export default function PrimaryCard(props) {
  const [userInput, setUserInput] = useState(0);

  // useEffect(() => {
  //   console.log("userInput changed to ", userInput);
  // }, [userInput]);

  const action1 = "Hire Farmers";
  const action2 = "Sell Lemons";

  return (
    <Card
      sx={{ minWidth: 275, boxShadow: 7, borderRadius: "30px" }}
      // style={{ backgroundColor: "#FFFDEF" }} // quite good

      style={{ backgroundColor: "#FEF8D7" }}
      // original #FFFDEC
      // darker
      // #FFFDEA
      // FEF8D7
      // FFF7CD
      // ligther
    >
      <CardContent sx={{ margin: 1 }}>
        <ThemeProvider theme={theme}>
          <Typography variant="h4" sx={{}}>
            <Box component="span" fontWeight="bold">
              Your Farm:
            </Box>
          </Typography>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              sx={{ marginTop: 0.7, marginBottom: 3, justifyContent: "center" }}
            >
              (Remember to change to{" "}
              <Box component="span" fontWeight="bold">
                BSC Main Net
              </Box>
              !)
            </Typography>
          </div>

          {/* <h1 className="title">Your Farm:</h1> */}

          {/* <Typography variant="h4" style={{ paddingBottom: 10 }}>
          DETAIL
        </Typography> */}

          {/* <ThemeProvider theme={theme}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ fontSize: 24, fontFamily: "Montserrat" }}
              color="text.primary"
              gutterBottom
            >
              CONTRACT:
            </Typography>

            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {props.contractBalance ?? "0"} BNB
            </Typography>
          </div>
        </ThemeProvider> */}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              Contract Worth
            </Typography>

            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {props.contractBalance ?? "0"} BNB
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              Wallet
            </Typography>

            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {props.walletBalance ?? "0"} BNB
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              Your Farmers
            </Typography>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {props.coinBalance ?? "0"} FARMER
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <TextField
              id="outlined-basic"
              label="BNB"
              variant="outlined"
              size="medium"
              onChange={(input) => {
                // const re = /^[0-9\b]+$/;

                // // if value is not blank, then test the regex

                // if (e.target.value === "" || re.test(e.target.value)) {
                //   setUserInput(input.target.value);
                // }

                // TODO: check for invalid input
                setUserInput(input.target.value);
              }}
              style={{
                minWidth: 300,
              }}
            />

            <div
              style={{ display: "flex", flexDirection: "row", padding: 5 }}
            />

            <ThemeProvider theme={buttonTheme}>
              <Button
                variant="contained"
                onClick={() => {
                  // console.log(action1);

                  /* A regular expression that checks if the input is a number. */
                  // const re = /^[0-9\b]+$/;
                  const re = /^(\d*\.)?\d+$/;

                  // if value is not blank, then test the regex
                  if (re.test(userInput)) {
                    // alert(userInput);
                    buyEggs(props.referral, userInput);
                  }
                  // else if (userInput === "") {
                  //   alert("userinput = 0");
                  // }
                  else {
                    alert("Please enter a valid number");
                  }
                }}
                style={{
                  height: buttonHeight,
                  // width: "50%",
                  minWidth: 300,
                }}
              >
                {action1}
              </Button>
            </ThemeProvider>
          </div>

          {/* <hr
          style={{
            color: "red",
            backgroundColor: color,
            height: 5,
          }}
        /> */}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              Your Lemons Worth
            </Typography>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {props.reward ?? "0"} BNB
            </Typography>
          </div>

          {/* <ButtonGroup
          variant="text"
          aria-label="outlined primary button group"
          size="large"
          // labelStyle={{ fontSize: "63px" }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        ></ButtonGroup> */}

          <ThemeProvider theme={buttonTheme}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                height: buttonHeight,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  // console.log(action2);
                  sellEggs();
                }}
                style={{ minWidth: 100, width: "32%" }}
              >
                {action2}
              </Button>
              <div
                style={{ display: "flex", flexDirection: "row", padding: 5 }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  hatchEggs();
                }}
                style={{
                  minWidth: 100,
                  width: "32%",
                }}
              >
                REINVEST
              </Button>
            </div>
          </ThemeProvider>

          {/* <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.primary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
        </ThemeProvider>
      </CardContent>

      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
