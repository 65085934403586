import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  ThemeProvider,
  Typography,
  CardContent,
  Link,
  Box,
} from "@mui/material";
import { ethers } from "ethers";

import {
  init,
  getContractTotalBalance,
  getUserMinerNumbers,
  getUserWalletBalance,
  getReward,
  getCurrentAddress,
} from "../web3/Web3Client";

import TopCard from "../components/TopCard";
import PrimaryCard from "../components/PrimaryCard";
import DetailCard from "../components/DetailCard";
import ReferalCard from "../components/ReferalCard";
import CardGroup from "../components/CardGroup";
// import InfoCard from "../components/InfoCard";

import logo from "../assets/lemon_logo_2.png";

// button theme
import { buttonTheme } from "../styles/buttonTheme";

import GothamWoff2 from "../fonts/gotham.woff2";
import Web3 from "web3";
import { TextTheme } from "../contexts/TextTheme";
import CountdownTimer from "../components/Timer/CountDownTimer";

// const theme = createTheme({
//   typography: {
//     fontFamily: "Raleway, Arial",
//   },
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: `
//         @font-face {
//           font-family: 'Raleway';
//           font-style: normal;
//           font-display: swap;
//           font-weight: 400;
//           src: local('Raleway'), local('Raleway-Regular'), url(${GothamWoff2}) format('woff2');
//           unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
//         }
//       `,
//     },
//   },
// });

// // const theme = createMuiTheme({
// //   typography: {
// //     // fontFamily: ["Chilanka", "cursive"].join(","),
// //     fontFamily: ["Gotham"].join(","),
// //   },
// // });

// // const HomePageStyle = css`
// //   h1 {
// //     font-size: 5rem;
// //     font-weight: 600;
// //     display: flex;
// //     justifycontent: center;
// //     text-align: center;
// //   }
// // `;

// change your coin name here
const projectName = "LEMON FARM";

const HomePage = () => {
  // window width
  const [width, setWidth] = useState(window.innerWidth);

  const [address, setAddress] = useState(null);
  const [contractBalance, setContractBalance] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [minerNumber, setMinerNumber] = useState(null); // your coin
  const [reward, setReward] = useState(null);
  const [connectButtonText, setConnectButtonText] = useState("Connect Wallet");
  const [errorMessage, setErrorMessage] = useState(null);

  // referral
  const [referral, setReferral] = useState(null);

  // check if wallet is available
  // download link: https://metamask.app.link/dapp/bnb-lemon.com
  // const [downloadMetaMaskPrompt, setDownloadMetaMaskPrompt] = useState(null);
  // const downloadLink = "https://metamask.app.link/dapp/bnb-lemon.com";

  const metaMaskLink = "https://metamask.io/download//";

  const siteLink = "https://bnb-lemon.com";

  useEffect(() => {
    // web3client init
    init();

    console.log("initializing");

    // // if no metamask, prompt user to install
    // if (window.ethereum && window.ethereum.isMetaMask) {
    //   setDownloadMetaMaskPrompt(true);
    // }

    // set reference
    getUserReference();

    getContractTotalBalance()
      .then((balance) => {
        // console.log("getContractBalance", typeof balance);
        let balanceInEther = ethers.utils.formatEther(balance);
        let roundedBalance = Math.round(balanceInEther * 100000) / 100000;
        setContractBalance(roundedBalance);
      })
      .catch((error) => {
        // setErrorMessage("getContractBalance", error.message);
        // console.log("getContractBalance failed", error);
      });

    // check  window size
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getUserWalletBalance(address)
      .then((balance) => {
        // console.log("getUserWalletBalance", balance);
        let bnb =
          Math.round(Web3.utils.fromWei(balance, "ether") * 100000) / 100000;
        setWalletBalance(bnb);
      })
      .catch((error) => {
        // console.log("getUserWalletBalance error");
      });

    getUserMinerNumbers(address)
      .then((balance) => {
        // console.log("updating user miners: ", balance);
        setMinerNumber(balance);
      })
      .catch((error) => {
        // console.log("getUserBalanceInContract", error.message);
        // alert("getUserMinerNumbers" + error.message);
      });

    getReward(address)
      .then((reward) => {
        // console.log("updating reward: ", reward);
        let rewardInEther = ethers.utils.formatEther(reward);
        let roundedReward = Math.round(rewardInEther * 100000) / 100000;
        setReward(roundedReward);
      })
      .catch((error) => {
        // console.log("getReward failed", error);
      });
  }, [address]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      // console.log("MetaMask Here!");

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          setConnectButtonText("Wallet Connected");
          getAccountBalance(result[0]);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      // console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");

      alert("Please install MetaMask browser extension to interact");

      // return (<DownloadWalletPage/>)
    }
  };

  const accountChangedHandler = (account) => {
    // console.log("account changed in accountChangedHandler: ", account);
    setAddress(account);
    getAccountBalance(account);
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  function getAccountBalance(account) {
    window.ethereum
      .request({ method: "eth_getBalance", params: [account, "latest"] })
      .then((balance) => {
        setWalletBalance(ethers.utils.formatEther(balance));
        // console.log("wallet balance: ", ethers.utils.formatEther(balance));
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  // listen for account changes
  if (window.ethereum && window.ethereum.isMetaMask) {
    getCurrentAddress().then((address) => {
      // console.log("getCurrentAddress", address);
      setAddress(address);
    });

    window.ethereum.on("accountsChanged", accountChangedHandler);
    window.ethereum.on("chainChanged", chainChangedHandler);
  }

  //   const fetchBalance = () => {
  //     getContractBalance()
  //       .then((balance) => {
  //         console.log("getOwnBalance: ", balance);
  //         setContractBalance(balance);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  function getUserReference() {
    if (window.location.href === siteLink) {
      // no reference,
      // console.log("no reference");
      return null;
    }

    // have reference
    let str = window.location.pathname.substring(1);

    setReferral(str);
  }

  const isMobile = window.innerWidth <= 570;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {isMobile && (
          <>
            <div style={{ padding: 10 }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <img src={logo} style={{ height: 100 }} />
            </div>
          </>
        )}

        {!isMobile && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <img src={logo} style={{ height: 160 }} />
              <div style={{ margin: 10 }}></div>
            </div>
          </>
        )}

        <ThemeProvider theme={buttonTheme}>
          {!address && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 50,
                paddingTop: 25,
                paddingBottom: 25,
              }}
            >
              <Button variant="contained" onClick={connectWalletHandler}>
                {connectButtonText}
              </Button>
              {/* <div style={{ padding: 5 }} />
                <Button variant="contained" href={metaMaskLink} target="_blank">
                  Download MetaMask
                </Button> */}
            </div>
          )}
        </ThemeProvider>

        {/*
        <ThemeProvider theme={TextTheme}>
          <div style={{ padding: 10 }}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Launch Time: 30 April 2022, 2:00 p.m. UTC
            </Typography>
          </div>
        </ThemeProvider> */}

        <CardGroup>
          <CountdownTimer
            countdownTimestampMs={new Date(
              "30 April 2022, 14:00:00 UTC"
            ).getTime()}
          />
          <ThemeProvider theme={TextTheme}>
            <Card
              sx={{
                // minWidth: props.minWidth ?? 275,
                // width: props.width ?? "90%",
                boxShadow: 7,
                borderRadius: "30px",
              }}
              style={{ backgroundColor: "#FEF8D7" }}
            >
              <CardContent sx={{ margin: 1 }}>
                {/* href here */}
                <Typography variant="h4" sx={{ marginBottom: 2.5 }}>
                  <Box component="span" fontWeight="bold">
                    SCAM ALERT!!{" "}
                  </Box>
                </Typography>

                <Typography sx={{ marginBottom: 2 }}>
                  LEMON FAMILY!! Someone is{" "}
                  <Box component="span" fontWeight="bold">
                    stealing the community's effort!!
                  </Box>{" "}
                </Typography>

                <Typography>
                  Our team recently found that there's scam website which{" "}
                  <Box component="span" fontWeight="bold">
                    looks exactly the same
                  </Box>{" "}
                  as ours! The real website is {"\n "}
                </Typography>

                <Typography sx={{ marginBottom: 2 }}>
                  <Box component="span" fontWeight="bold">
                    bnb-lemon.com
                  </Box>{" "}
                  and{" "}
                  <Box component="span" fontWeight="bold">
                    NOT bnblemon.com!!!
                  </Box>
                </Typography>

                <Typography>
                  You can{" "}
                  <Box component="span" fontWeight="bold">
                    verify
                  </Box>{" "}
                  by going through our{" "}
                  <Box component="span" fontWeight="bold">
                    marketing videos
                  </Box>
                </Typography>

                <Typography sx={{ marginBottom: 1 }}>
                  (pay attention to that{" "}
                  <Box component="span" fontWeight="bold">
                    hyphen
                  </Box>{" "}
                  in bnb-lemon.com):
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 20,
                  }}
                >
                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=wAtrkCbz35Q&ab_channel=DownTheDEFIRabbitHole"
                    color="inherit"
                  >
                    Down The DEFI Rabbit Hole
                  </Link>

                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=vNdYZq36Aho&feature=youtu.be&ab_channel=CryptoLifestyle"
                    color="inherit"
                  >
                    CryptoLifestyle
                  </Link>

                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=5w1KvkZD6wc&ab_channel=DEFIDONS"
                    color="inherit"
                  >
                    DEFI DONS
                  </Link>

                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=rFCpBAl-gck&ab_channel=FacturaDesdeCasa"
                    color="inherit"
                  >
                    Factura Desde Casa
                  </Link>

                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=K0ZiONaZtFI"
                    color="inherit"
                  >
                    Afonsotrader
                  </Link>

                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=uuubHalgyYw&ab_channel=%ED%81%AC%EB%A6%BD%ED%86%A0%EA%B2%8C%EC%9D%B4%EB%A8%B8Cryptogamer"
                    color="inherit"
                  >
                    크립토게이머Cryptogamer
                  </Link>
                  <Link
                    sx={{ margin: 0.5 }}
                    href="https://www.youtube.com/watch?v=EEuWYib-m2U"
                    color="inherit"
                  >
                    Grow My Bitcoin
                  </Link>
                </div>
              </CardContent>
            </Card>

            <div style={{ padding: 10 }} />

            <Card
              sx={{
                // minWidth: props.minWidth ?? 275,
                // width: props.width ?? "90%",
                boxShadow: 7,
                borderRadius: "30px",
              }}
              style={{ backgroundColor: "#FEF8D7" }}
            >
              <CardContent sx={{ margin: 1 }}>
                {/* href here */}
                <Typography variant="h4" sx={{ marginBottom: 2.5 }}>
                  <Box component="span" fontWeight="bold">
                    Audit Report{" "}
                  </Box>
                  by HazeCrypto
                </Typography>

                <Typography>
                  HazeCrypto audit found{" "}
                  <Box component="span" fontWeight="bold">
                    no vulnerabilities, backdoors or any scam scripts{" "}
                  </Box>
                  on BNB Lemon. Feel safe to hire farmers!!
                </Typography>

                <div style={{ padding: 10 }} />

                <Link
                  href="https://hazecrypto.net/audit/bnblemon"
                  color="inherit"
                >
                  Find the full audit report here
                </Link>
              </CardContent>
            </Card>
          </ThemeProvider>

          <div style={{ margin: 10 }} />

          {isMobile && (
            <>
              <TopCard projectName={projectName} isMobile={true} />
            </>
          )}
          {!isMobile && (
            <>
              <TopCard projectName={projectName} isMobile={false} />
            </>
          )}

          <div style={{ margin: 10 }} />

          <PrimaryCard
            coinName={projectName}
            contractBalance={contractBalance}
            walletBalance={walletBalance}
            coinBalance={minerNumber}
            referral={referral}
            reward={reward}
          />
          <div style={{ margin: 10 }} />
          <DetailCard coin={projectName} />
          <div style={{ margin: 10 }} />

          <ReferalCard siteLink={siteLink} address={address} />

          <div style={{ margin: 10 }} />
          {/* <InfoCard name={projectName} /> */}
        </CardGroup>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{ fontSize: 16, paddingBottom: 5 }}
          color="text.primary"
          gutterBottom
        >
          ©BNB-LEMON 2022
        </Typography>
      </div>
    </div>
  );
};

export default HomePage;
