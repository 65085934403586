import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "typeface-montserrat";
import { TextTheme } from "../contexts/TextTheme";
import { ThemeProvider } from "@mui/material";

export default function DetailCard(props) {
  return (
    <ThemeProvider theme={TextTheme}>
      <Card
        sx={{ minWidth: 275, boxShadow: 7, borderRadius: "30px" }}
        style={{ backgroundColor: "#FEF8D7" }}
      >
        <CardContent sx={{ margin: 1 }}>
          <Typography variant="h4" sx={{ marginBottom: 2.5 }}>
            <Box component="span" fontWeight="bold">
              Nutrition Facts:
            </Box>
          </Typography>

          {/* <h1 className="title">Nutrition Facts:</h1> */}

          {/* <Typography variant="h4" color="text.primary" gutterBottom>
          {props.coin} DETAIL
        </Typography> */}

          {/* <br /> */}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              Daily Return
            </Typography>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              ~8%
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              APR
            </Typography>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              ~2920%
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              Dev Fee
            </Typography>
            <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              5%
            </Typography>
          </div>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
